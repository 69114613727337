/* explore css */

.request {
    background-image: url(../../assets/img/demoRequest.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 70vh !important;
    margin-top: 30px;
    margin-left: -7px;

}

.subText {
    width: 70%;
    margin-bottom: 50px;
}

.red_box_image {
    margin-left: 15%;
    margin-right: -3%;
}

.checkboxRequest {
    margin-right: 10px;
}

.checkboxRequest:checked {
    background-color: #00ABE7;
}

.generation {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.text_grey_dark {
    color: #969696 !important;
}

.grey_box {
    background-color: rgba(216, 216, 218, 0.1);
    border-radius: 4px;
    border: 1px solid #969696;
    min-height: 100px;
    width: 100%;
    display: flex;
    padding: 15px 20px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
}

.grey_box_full {
    background-color: rgba(216, 216, 218, 0.1);
    border-radius: 4px;
    border: 1px solid #969696;
    min-height: 100px;
    width: 100%;
    padding: 15px 20px;
    margin-bottom: 50px;
}

.blue_light_box {
    background-color: rgba(0, 171, 231, 0.05);
    border-radius: 4px;
    border: 1px solid #969696;
    min-height: 100px;
    width: 100%;
    display: flex;
    padding: 15px 20px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
}

.blue_box {
    background-color: rgba(0, 171, 231);
    border-radius: 4px;
    border: 1px solid #969696;
    min-height: 100px;
    width: 100%;
    display: flex;
    padding: 15px 20px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
}

.red_box {
    background-color: #DE0009;
    border-radius: 4px;
    border: 1px solid #969696;
    min-height: 100px;
    width: 100%;
    display: flex;
    padding: 15px 15px;
    justify-content: space-around;
    align-items: center;
}

.borderTop {
    border-top: 1px dashed #101D46;
    padding-top: 10px;
    margin: 0px;
}

.agile-section {
    background-color: rgba(0, 171, 231, 0.05);
}

.btn_blue_outline {
    width: 100%;
    background-color: #fff;
    color: #00ABE7;
    border: 1px solid #00ABE7;
}

@media screen and (max-width:1200px) {
    .request {
        height: 400px !important;
        background-image: url(../../assets/img/shippingBgMobile.png) !important;
        background-size: cover;
    }

    .grey_box_full {
        border: none;
        text-align: center;
    }
    .red_box_image {
        margin-left: 10%;
        margin-right: -3%;
    }
}