/* explore css */

/* .whoweare {
    background-image: url(../../assets/svg/Group\ 7121.svg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 70vh !important;
    margin-top: 40px;
} */

.subText {
    width: 70%;
    margin-bottom: 50px;
}

.weeks[type='radio']:checked::before {
    width: 15px !important;
    height: 15px !important;
}

.generation {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.borderTop {
    border-top: 1px dashed #101D46;
    padding-top: 10px;
    margin: 0px;
}

.agile-section {
    background-color: rgba(0, 171, 231, 0.05);
}

.btn_blue_outline {
    width: 100%;
    background-color: #fff;
    color: #00ABE7;
    border: 1px solid #00ABE7;
}

@media screen and (max-width:1200px) {
    .whoweare {
        height: 400px !important;
        /* background-image: url(../../assets/img/honebgMobile.png); */
        background-size: cover;
    }

    .mtm--50 {
        margin-top: -100px !important;
    }
}