.top_banner_email {
    width: 100%;
    height: 95vh;
    z-index: -1;
    background: url(../../assets/img/top_banner.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.top_banner_email1 {
    width: 100%;
    height: 95vh;
    z-index: -1;
    background: url(../../assets/img/service_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.top_banner_email2 {
    width: 100%;
    height: 95vh;
    z-index: -1;
    background: url(../../assets/img/AboutUs.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.dashedline {
    border: 1px dashed #0E1F50;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.verification {
    color: #0E1F50;
    text-align: center;
}

.numberCode {
    color: #00ABE7;
    text-align: center;
}

.signButton {
    color: #00ABE7;
    background-color: #fff;
    border: 1px solid #00ABE7;
    border-radius: 50px;
    width: 50%;
    height: 50px;
}

.displayCenter {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}