@font-face {
    font-family: "Open Sans Bold";
    src: url("./assets/fonts/OpenSans-ExtraBold.woff") format("woff"),
        url("./assets/fonts/OpenSans-ExtraBold.ttf") format("ttf")
}

body {
    /* font-family: "Open Sans", sans-serif; */
    font-size: 14px;
    color: #0E2050;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    /* font-weight: 900 !important; */
}

.font-open-bold {
    font-family: "Open Sans Bold" !important;
}

img,
svg {
    vertical-align: middle;
    max-width: 100%;
}

ul,
p {
    margin: 0;
    padding: 0;
}

body,
html {
    overflow: auto;
}

li {
    list-style-type: none;
}

section {
    padding: 50px 0;
}


/* body, html {
      overflow-x: hidden;
  } */


/****header start here********/

.profile_bar {
    background: #00A099;
    padding: 10px 0;
}

.profile_image {
    background: #f2f2f2;
    height: 50px;
    width: 50px;
    border-radius: 100%;
}

.profile_box h3 {
    margin: 0;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}

.profile_box a {
    color: #0E2050;
    text-transform: uppercase;
    font-size: 12px;
}

.custom_login_nav {
    background: #0E1F50;
}

.custom_login_nav .navbar .navbar-nav li a {
    color: #6E7896;
}

.custom_login_nav .navbar .navbar-nav li a.active {
    color: #fff;
    font-weight: 600;
}

.custom_login_nav .navbar .navbar-nav li a:hover {
    color: #fff;
}

.custom_login_nav .navbar-brand img {
    height: 40px;
    margin: 10px 0px;
}

.dropbtn {
    border: none;
    background: transparent;
    padding: 25px 0;
}

.dropdown .dropbtn img {
    height: 20px;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.navbar.widthfull {
    position: inherit;
    padding: 0;
}

.dropdown.megamenu {
    position: inherit;
}

.dropdown-content {
    width: 100%;
    left: 0;
    right: 0;
}

.menu_box p {
    color: #0E1F50;
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
}

.menu_box a {
    padding: 0 !important;
    margin: 2px 0;
    font-weight: 200;
}

.dropdown.megamenu .dropdown-content {
    padding: 20px 0;
    top: 100px;
    background: #fff;
    box-shadow: none;
}

.dropdown.megamenu .dropdown-content .menu_box a {
    color: #000 !important;
    padding: 3px 0 !important;
    font-size: 11px;
    margin: 0 !important;
}

.subscribeArrow {
    width: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #fff;
    z-index: 2;
    height: 161px !important;
    margin-left: 10px;
    margin-bottom: 0px;
    cursor: pointer;
    background-color: #dddddd;
    /* margin-top: -80px !important; */
    /* margin-right: 10px; */
}

.subscribeArrow1 {
    width: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #fff;
    z-index: 2;
    height: 161px !important;
    margin-left: 10px;
    margin-bottom: 0px;
    cursor: pointer;
    background-color: #0D1F4E;
    /* margin-top: -80px !important; */
    /* margin-right: 10px; */
}

.dropdown.megamenu .dropdown-content .menu_box a:hover {
    color: #00a099 !important;
}

.login_nav.home_nav {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 2;
}

.home_nav .custom_login_nav.home_nav_bg {
    background: transparent;
    border-bottom: 1px solid #ffffff1c;
}

.greenTopbg.homebg_blue {
    background: #0E1F50;
}

.homebgBlackLight {
    background: rgba(0, 0, 0, 0.25);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.login_nav.home_nav .custom_login_nav .navbar .navbar-nav li a {
    color: #fff;
}

.greenTopbg {
    padding: .6rem 0 .6rem 0;
    background-color: #00A099;
}

.greenTop {
    text-align: right;
}

.greenTop li {
    display: inline-block;
}

.greenTop ul li a {
    font-weight: 300;
    text-transform: uppercase;
}

.greenTop li a {
    color: #fff;
    padding-left: 15px;
}

.greenTop a.login_btn {
    color: #F9BA00;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.border-round {
    border: 1px solid #F9BA00;
    border-radius: 30px;
    padding: 6px;
    font-size: 8px;
    width: 22px;
    margin-left: 5px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login_btn-logout {
    color: #0E2050;
    text-transform: capitalize;
    margin-left: 10px;
    cursor: pointer;
}

.login_btn-logout:hover {
    text-decoration: underline;
}

.greenTopbg.homebg_blue a.create_account_btn {
    color: #009f99;
    font-size: 12px;
    margin-top: 3px;
}

.greenTop a.create_account_btn {
    margin-top: 2px;
    font-size: 12px;
    color: #0E2050;
}

.login_nav .custom_login_nav .navbar .navbar-nav li a {
    color: #fff;
    margin: 0 5px;
}

.ham_button img {
    height: 20px;
    cursor: pointer;
}

.custom_login_nav.join_nav .navbar .navbar-nav li a {
    color: #5f6986;
    margin: 0 5px;
}

.custom_login_nav.join_nav .navbar .navbar-nav li a.active {
    color: #fff;
}


/****header end here********/


/**index Page start here***/

.top_banner {
    width: 100%;
    height: 95vh;
    margin-top: 40px;
    /* top: 50; */
    z-index: -1;
    background: url(./assets/img/top_banner.png);
    background-size: cover;
    background-repeat: no-repeat;
    /* position: relative; */
}

.skillDialogue {
    min-width: 80% !important;
}

.videoTag {
    z-index: 0;
    height: 102%;
    width: 100%;
    /* float: left; */
    top: 0;
    object-fit: cover;
    padding: none;
    /* overflow: ; */
    position: absolute;
    /* optional depending on what you want to do in your app */


}

.skillsChip {
    width: 23%;
    margin-right: 2% !important;
    height: 45px !important;
    border-radius: 100px !important;
}
.css-y37vqi-MuiButtonBase-root-MuiChip-root{
    background-color: #4BAAE2 !important;

}

.signBG {
    background-image: url(./assets/img/loginbg.png) !important;
    height: 70vh !important;
}

.hoverEffect:hover {
    color: #fff !important;
}

.top_banner_expert {
    width: 100%;
    height: 100vh;
    top: 0;
    z-index: 0;
    background: url(./assets/img/top_banner.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.become_an_expert {
    background-image: url(./assets/img/service_bg.png) !important;
}

.become_an_expert_div {
    align-items: center;
    margin-top: 50px;
    display: flex;
}


.drawer-header {
    background-color: #0f1f50 !important;
}

.greenTopMobile {
    background-color: #00968E !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15px;
    margin-bottom: 0px;
    height: 50px;
    flex-direction: row;
}

.drawer-body {
    background-color: #0f1f50 !important;
}

.drawer-body>p {
    color: #fff !important;
}

.rs-drawer-left.rs-drawer-xs, .rs-drawer-right.rs-drawer-xs {
    width: 300px !important;
}

.heading_font,
.top_banner h1 {
    font-size: 42px;
    color: #fff;
    /* font-weight: 700; */
    margin-bottom: 30px;
}

.btn_link {
    color: #F9BA00;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
}

.btn_link:hover {
    text-decoration: underline !important;
}

.btn_link span {
    font-weight: 300;
    margin-left: 5px;
}

.banner_btm_link_div {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.banner_btm_link a {
    color: #A6A5A7;
    font-weight: 300;
    font-size: 14px;
    position: relative;
}

.banner_btm_link div {
    color: #A6A5A7;
    font-weight: 300;
    font-size: 14px;
    position: relative;
}

.banner_btm_link a div {
    visibility: hidden;
}

.banner_btm_link a.active,
.banner_btm_link a:hover div {
    color: #fff;
    font-weight: 600;
}

.banner_btm_link a.active div,
.banner_btm_link a:hover div {
    visibility: visible;
}


/* .banner_btm_link a:hover {
  color: #fff;
  font-weight: 600;
} */


/* .banner_btm_link a:hover div {
  display: block;
} */

.banner_btm_link {
    /* position: absolute; */
    z-index: 2;
    width: 100%;
    bottom: 30px;
    left: 0;
}

.banner_content {
    overflow: hidden;
}

.banner_btm_link a div {
    height: 4px;
    width: 47px;
    background: #009F99;
    display: block;
    position: absolute;
    left: 0;
    top: -6px;
}

.second_section {
    height: 330px;
    background: #00ABE7;
    background-image: url(./assets/img/dr_bg.png);
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: 50%;
}

.second_section .content p {
    font-size: 14px;
    margin: 0px 0 20px 0;
    color: #fff;
}

.second_section .content a {
    color: #0E1F50;
}

.contentBorder {
    height: 100vh;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-top: -1px;
    border-right: 1px solid rgb(133, 131, 131) !important;
}

.contentHover {
    width: 100%;
    z-index: 2;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}

.contentHoverDiv {
    width: 100%;
    z-index: 2;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.contentHover:hover {
    width: 100%;
    z-index: 2;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7) !important;
}

.bg-blue {
    background-color: #0e1f50 !important;
}

.text_grey {
    color: #D8D8DA !important;
}

.width80 {
    width: 80% !important;
}

.bg-blue-light {
    background-color: #F2FAFD !important;
}

.web80Mobile100 {
    width: 80%;
}

.contentHover>div>p {
    color: #00a099;
    font-size: 16px;
    margin-top: 20px;
    cursor: pointer !important;
    font-weight: 500;
}

.contentHover>div>a {
    color: #fff;
}

.contentHover>div>h1 {
    color: #fff;
}

.contentHover>div>a:hover {
    color: #fff;
    display: flex !important;
}


/* .banner_content h1 {
      margin-bottom: 20px;
  } */

.offer_section .heading_font {
    color: #0e1f50;
    margin-bottom: 20px;
    /* font-weight: 700; */
}

.offer_section.sec_pd p {
    font-size: 14px;
    max-width: 1000px;
    margin-bottom: 30px;
}

.offer_section.sec_pd {
    padding-bottom: 0;
}

.offer_section.sec_pd a {
    font-size: 14px;
    max-width: 1000px;
    margin-bottom: 50px;
}

.offer_section.sec_pd .container {
    /* border-bottom: 1px dotted; */
}

.text_green {
    color: #00A099 !important;
}

.success-message {
    background-color: rgb(56, 142, 60) !important;
    color: #fff !important
}

.rs-message-body {
    color: #fff !important
}

.text_red {
    color: red !important;
}

.font-normal {
    font-weight: normal !important;
}

.text_blue {
    color: #0E1F50 !important;
}

.text_yellow {
    color: #F9BA00 !important;
}

small {
    color: red !important;
}

.text_blue_light {
    color: #00ABE7 !important;
}

.ph-0 {
    padding-left: 0% !important;
    padding-right: 0% !important;
}

.mh-0 {
    margin-left: 0% !important;
    margin-right: 0% !important;
}

.text_blue_opacity {
    color: #0E1F50 !important;
    opacity: 0.4;
}

.font-12 {
    font-size: 12px;
}

.font-13 {
    font-size: 13px;
}

.mtn-5 {
    margin-top: -5px;
}

.font-14 {
    font-size: 14px;
}

.font-16 {
    font-size: 16px;
}

.font-18 {
    font-size: 18px;
}

.font-24 {
    font-size: 24px;
}

.font-40 {
    font-size: 40px !important;
}

.font-30 {
    font-size: 30px !important;
}

.font-36 {
    font-size: 36px !important;
}

.font-60 {
    font-size: 60px !important;
}

.font-80-home {
    z-index: 6;
    min-width: 533px;
    max-width: 1000px;
    white-space: normal;
    font-size: 80px !important;
    line-height: 85px;
    font-weight: 800;
    color: rgb(255, 255, 255);
    letter-spacing: -1px;
    font-family: Montserrat;
    visibility: inherit;
    transition: none 0s ease 0s;
    text-align: inherit;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    min-height: 0px;
    max-height: none;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
}

.font-80-login {
    z-index: 6;
    /* min-width: 533px; */
    /* max-width: 1000px; */
    white-space: normal;
    font-size: 40px !important;
    line-height: 40px;
    margin-bottom: 10px !important;
    font-weight: 800;
    color: rgb(255, 255, 255);
    letter-spacing: -1px;
    font-family: Montserrat;
    visibility: inherit;
    transition: none 0s ease 0s;
    text-align: inherit;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    min-height: 0px;
    max-height: none;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
}

.homeSubText {
    z-index: 7;
    min-width: 559px;
    max-width: 559px;
    white-space: normal;
    font-size: 20px !important;
    line-height: 35px;
    font-weight: 300;
    color: rgb(255, 255, 255);
    letter-spacing: 0px;
    font-family: Merriweather;
    visibility: inherit;
    transition: none 0s ease 0s;
    text-align: inherit;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    min-height: 71px;
    max-height: 71px;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
}

.homeSubText1 {
    z-index: 7;
    /* min-width: 559px; */
    /* max-width: 350px; */
    white-space: normal;
    font-size: 14px !important;
    line-height: 25px;
    font-weight: 300;
    color: rgb(255, 255, 255);
    letter-spacing: 0px;
    font-family: Merriweather;
    visibility: inherit;
    transition: none 0s ease 0s;
    text-align: inherit;
    border-width: 0px;
    margin: 0px;
    margin-bottom: 30px !important;
    padding: 0px;
    /* min-height: 71px; */
    /* max-height: 71px; */
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
}

.hoverWhite:hover {
    color: #fff !important
}

.readmoreBox {
    width: 180px;
    height: 60px;
    border: 1px solid #fff;
    align-items: center;
    justify-content: center;
    display: flex;
    color: #fff !important;
    margin-top: 20px;
}

.hGreenLine {
    width: 80px;
    height: 3px;
    background-color: #00a099;
    border-radius: 5px;
    margin-right: 20px;
}

.readmoreBox>a {
    color: #fff !important;
    font-weight: 800;
}

.underlineNone:hover {
    text-decoration: none;
}

.hoverWhite:hover {
    color: white !important;
}

.borderhoverWhite:hover {
    border-color: white !important;
}

.image_box img {
    max-height: 300px;
    /* height: 100%; */
}

.lineHight{
    line-height: 35px !important;
}

.heading_font_black {
    color: #0E1F50;
    /* font-weight: 700; */
}

.sec-4 .content.content-right {
    text-align: right;
    padding-right: 30px;
}

.sec-4 .content.content-left {
    text-align: left;
    padding-left: 30px;
}

.sec-4 .content .btn_link {
    margin-top: 30px;
    display: inherit;
}

.link_black {
    color: #0E1F50 !important;
}

.content .btn_link {
    margin-top: 30px;
    display: inherit;
}

.service_sec_heading.heading_font {
    position: absolute;
    top: 50px;
    z-index: 2;
}

.services_sec {
    background: url(./assets/img/service_bg.png);
    padding: 0;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.services_sec .content,
.services_sec .content h3 {
    color: #fff;
}

.services_sec .content p {
    display: none;
}

.services_sec .content.active p {
    display: block;
}

.height100 {
    height: 100% !important;
}

.height100vh {
    height: 100vh !important;
}

.hoverGreen:hover {
    color: #00968E;
    cursor: pointer;
}

.services_sec .content {
    height: 80vh;
    border-right: 1px solid #fff;
    padding-right: 20px;
    padding-bottom: 30px;
    cursor: pointer;
}

.services_sec .contentDiv:hover {
    background-color: rgba(0, 0, 0, 0.5);
    padding-right: 0px !important;
}

.services_sec .contentDiv {
    padding-right: 0px !important;
}

.services_sec .content:hover p {
    display: block;
}

.recent_post_sec {
    background: #DDD;
    padding: 0;
    border-bottom: 1px solid #fff;
}

.arrow-center-sm {
    flex-direction: column;
    display: block !important;
    align-items: flex-end;
}

.gray_box>i {
    font-size: 30px;
    color: rgb(128, 126, 126);
}

.gray_box {
    padding: 30px 0;
}

.gray_box h3 {
    color: #969696;
    margin-bottom: 5px;
}

.gray_box a {
    font-size: 24px;
    font-weight: 300;
    color: #dfdede;
}

.text-right {
    text-align: right;
}

.border-right {
    border-right: 1px solid #fff;
}

.border-none {
    border: none !important;
}

.subs_newsletter .logingroup input {
    width: 100%;
    border-radius: 0;
    border: 0.5px solid #0E1F50;
    height: 40px;
    margin-bottom: 0px !important;
    padding: 0 10px;
    font-weight: 300;
    color: #0E1F50;
    /* border-right: 40px solid; */
    background: #dddddd;
}

.subs_newsletter .grey-border input {
    width: 100%;
    border-radius: 0;
    border: 0.5px solid #dddddd;
    height: 40px;
    padding: 0 10px;
    font-weight: 300;
    color: #dddddd;
    /* border-right: 40px solid; */
    background: #0E1F50;
}

.logingroup textarea {
    width: 100%;
    font-size: 14px;
    padding: 10px;
    height: 100px;
    border: 1px solid #dfdede;
    border-radius: 5px;
}

.subs_newsletter .logingroup:last-child input {
    border-bottom: 0.5px solid #0E1F50;
}

.subs_newsletter .grey-border:last-child input {
    border-bottom: 0.5px solid #dddddd;
}

.home_footer {
    padding: 40px 0;
    background: #DDDDDD;
}

.subs_newsletter form h3 {
    font-weight: 400;
    color: #0E1F50;
}

.company_address a img {
    height: 50px;
    margin-bottom: 20px;
}

.company_address h3 {
    color: #85868A;
    margin-bottom: 10px;
}

.company_address p {
    color: #85868A;
    font-weight: 300;
}

.common_footer {
    background: #0E1F50;
    padding: .85rem 0;
}

.font-weight-bolder {
    font-weight: bolder !important;
}

.footsocial ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.footsocial li {
    display: inline;
}

.footleft li a {
    color: rgb(128, 126, 126);
    font-size: 8px;
    font-weight: 300;
    padding: 0 .5rem;
    display: inline-block;
}

.footleft {
    text-align: right;
}

.footleft ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.footleft li {
    display: inline;
}

.fab {
    color: #FFFFFF;
    font-size: 16px;
    margin-bottom: 0;
    margin-right: 5px;
}


/**index page end here**/


/**join page start**/

.loginPara {
    max-width: 570px;
    height: 240px;
    width: 100%;
    margin-bottom: 10px;
}

.loginPara h3 {
    font-size: 30px;
    color: #00968E;
    margin-bottom: 1rem;
}

.loginPara p {
    font-size: 16px;
}

.signupPara {
    max-width: 570px;
    width: 100%;
    margin-bottom: 10px;
}

.signupPara h3 {
    font-size: 30px;
    color: #00968E;
    margin-bottom: 1rem;
}

.signupPara p {
    font-size: 16px;
}

.logingroup {
    margin-bottom: 30px;
}

.inputMargin {
    margin-bottom: 10px !important;
}

.inputMargin1 {
    margin-bottom: -10px !important;
}

.cursor-pointer {
    cursor: pointer;
}

.forgotOuter {
    min-height: 75vh;
}

.inputMargin2 {
    margin-bottom: -5px !important;
}

.footerInputs {
    margin-bottom: 0px;
    width: 100%;
}

.logingroup .note {
    font-size: 12px;
    line-height: 16px;
    margin-top: 7px;
    color: #dfdede;
}

.logingroup input {
    width: 100%;
    font-size: 14px;
    padding: 10px;
    margin-bottom: 5px;
    height: 33px;
    border: 1px solid #dfdede;
    border-radius: 5px;
}

.companyInput {
    width: 85%;
    margin-left: 2%;
}

.companyInput1 {
    width: 85%;
    margin-left: 2%;
}

.mtMobile {
    margin-top: -5px;
}

.mtn-10 {
    margin-top: -10px;
}

.logingroup textarea {
    border: 1px solid #b6b5b5;
    resize: none;
}

.logingroup input:focus-visible {
    border: none;
    box-shadow: none;
    outline: 1px solid #00a099;
}

.logingroup .btn-outline-warning {
    color: #fbbb00;
    border-color: #fbbb00;
    background-color: #fff;
    padding: .75rem 0rem;
    border-radius: 5rem;
    cursor: pointer;
    transition: all .4s ease;
    max-width: 330px;
    width: 100%;
}

.btn-outline-green-warning {
    color: #00968E;
    border-color: #00968E;
    background-color: #fff;
    padding: .75rem 0rem;
    border-radius: 5rem;
    cursor: pointer;
    transition: all .4s ease;
    max-width: 340px;
    width: 100%;
}

.btn-outline-green-warning:hover {
    background-color: #00968E0D;
}

.logingroup .btn-outline-warning:hover {
    background-color: #fbbb00;
    color: #fff;
}

.logingroup .btn-login-outline-warning:hover {
    background-color: #fbbb00;
    color: #fff;
}

.logingroup .btn-login-outline-warning:active {
    outline: none;
    color: #fff;
}

.logingroup .btn-login-outline-warning {
    color: #fbbb00;
    background-color: #fff;
    border-color: #fbbb00;
    padding: .75rem 0rem;
    border-radius: 5rem;
    cursor: pointer;
    transition: all .4s ease;
    width: 100%;
}

.menu-icon {
    color: #fff;
    font-size: 30px;
}

.nav-link:hover {
    color: #0094DA !important;
}

.underlineHidden:hover {
    text-decoration: none !important;
}

.logingroup .btn-login-outline-warning-active {
    background: #fbbb00;
    color: var(--white-color);
    border-color: #fbbb00;
    padding: .75rem 0rem;
    border-radius: 5rem;
    cursor: pointer;
    transition: all .4s ease;
    width: 100%;
}

.logingroup .btn-outline-warning-active {
    background: #fbbb00;
    color: var(--white-color);
    border-color: #fbbb00;
    padding: .75rem 0rem;
    border-radius: 5rem;
    cursor: pointer;
    transition: all .4s ease;
    max-width: 300px;
    width: 100%;
}

.logingroupUpload {
    display: flex;
    align-items: center;
}

.logingroupUpload button {
    border: 1px solid #0c90ce;
    background: #e5f4fb;
    color: #0593d1;
    padding: 0 3rem;
    height: 35px;
    border-radius: .5rem;
}

.logingroupUpload span {
    padding-left: .85rem;
    font-weight: 200;
    color: #c3c2c2;
}

.logingroupIn {
    display: flex;
    align-items: center;
    border: 1px solid #0f1f50;
    border-radius: .5rem;
}

.logingroupIn button,
.select_wrap button {
    border: none;
    background: var(--secondary-color);
    color: var(--white-color);
    padding: 0 12px;
    height: 32px;
    border-radius: 0rem .3rem .3rem 0rem;
}

.logingroup .logingroupIn input {
    border: none;
}

.loginPara h4 {
    color: #00A099;
    font-size: 20px;
    font-weight: 600;
}

.loginQuestion h5 {
    color: #0E1F50;
    font-size: 1.6rem;
    font-weight: 400;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.upload-btn-wrapper .btn {
    border: 1px solid #0c90ce;
    background: #e5f4fb;
    color: #0593d1;
    padding: 0 3rem;
    height: 35px;
    border-radius: .5rem;
    font-size: 14px;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.select_wrap {
    position: relative;
    width: 100%;
}

.form-select {
    font-size: 14px;
    height: 33px;
    border: 1px solid #dfdede;
    border-radius: 5px;
    background: transparent;
    background-image: none;
    width: 100%;
}

.logingroup .logingroupIn {
    border: none;
    margin-bottom: 5px;
}

.select_wrap button {
    background: #0E1F50;
    top: 0;
    position: absolute;
    right: 0;
    color: #fff;
    z-index: 1;
}

.select_wrap .form-select:focus {
    border-color: #dfdede;
    outline: 0;
    box-shadow: none;
}

.blue-btn {
    border: 1px solid #00ABE7;
    color: #00ABE7;
    min-width: 300px;
    background-color: #89d4f0;
}

.grey-btn {
    border: 1px solid #8B8B8B;
    color: #8B8B8B;
    min-width: 300px;
    background-color: rgb(214, 216, 218);
}

.rounded {
    border-radius: 100px !important;
}

.joinVideo {
    /* background: #fff; */
    margin-top: -15%;
    padding: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 700px;
    /* min-height: 605px; */
    width: 100%;
}


.joinVideoBlack {
    background: #6e6e70;
    /* padding: 3rem; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 450px !important;
    /* display: flex; */
    /* justify-content: flex-end !important;
  align-items: flex-end; */
}

.joinVideoDardBlack {
    background: #1F1F1F;
    margin-top: -150px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px !important;
    display: flex;
    justify-content: center !important;
    align-items: center;
}

.videocoming {
    border: 1px solid #969696;
    color: #969696;
    padding: 10px 20px;
    font-style: italic;
}

.videoemail {
    z-index: 0;
    width: 90% !important;
    height: 90% !important;
    position: absolute;
    /* margin-left: 50%; */
}

.videoemail1 {
    z-index: 0;
    width: 100% !important;
    height: 625px !important;
    position: absolute;
    /* margin-left: 50%; */
}

.videoemailRecording {
    z-index: 0;
    width: 100% !important;
    height: 450px !important;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.firstSection4SecHead {
    padding-left: 9%;
}

.lastSection4Sec {
    padding-right: 10%;
    width: 30% !important;
}

.lastSection4SecContent {
    width: 70% !important;
}

.sec4 {
    display: flex;
    align-items: flex-end;
}

.firstSection4Sec {
    padding-left: 10%;
    width: 30%;
}

.middle4Sec {
    width: 20%;
}

.emTeya {
    width: 100% !important;
    min-height: 450px !important;
}

.positionbutton {
    background-color: #0c90ce;
    width: 200px;
    height: 40px;
    border-radius: 5px;
    color: #fff;
    margin-right: 10px;
    font-size: 16px;
}

.applybutton {
    background-color: #0c90ce;
    width: 200px;
    height: 40px;
    border-radius: 5px;
    color: #fff;
    margin-right: 10px;
    font-size: 16px;
}

.text-grey {
    color: #D8D8DA;
    font-weight: 500;

}

.camerabutton {
    background-color: #009F99;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    color: #fff;
    margin-right: 10px;
    font-size: 20px;
}

.submitbutton {
    border: 1px solid #009F99;
    color: #009F99 !important;
    background-color: #fff;
    width: 200px;
    font-weight: bold;
    height: 50px;
    border-radius: 25px;
    color: #fff;
    margin-right: 10px;
    font-size: 20px;
}

.submitbutton {
    border: 1px solid #009F99;
    color: #009F99 !important;
    background-color: #fff;
    width: 200px;
    font-weight: bold;
    height: 50px;
    border-radius: 25px;
    color: #fff;
    margin-right: 10px;
    font-size: 20px;
}

.submitbutton:hover {
    border: 1px solid #009F99;
    background-color: #009F99 !important;
    width: 200px;
    font-weight: bold;
    height: 50px;
    border-radius: 25px;
    color: #fff !important;
    margin-right: 10px;
    font-size: 20px;
}

.ml-minus {
    margin-left: -2px;
}

.camerabutton:focus {
    outline: none;
}

.closebutton {
    background-color: #ff1100;
    width: 50px;
    font-size: 20px;
    height: 50px;
    border-radius: 25px;
    color: #fff;
    margin-right: 10px;
}

.closebutton:focus {
    outline: none;
}

.buttonBox {
    z-index: 5;
    height: 100% !important;
    display: flex;
    align-items: flex-end;
    margin-bottom: 50px;
}

.joinVideoBox {
    /* z-index: 3; */
    width: 100% !important;
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.joinVideoEmail {
    /* background: #fff; */
    margin-top: 50px;
    /* padding: 3rem; */
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 550px;
    width: 100%;
}

.joinVideoBoxEmail {
    /* z-index: 3; */
    width: 100% !important;
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.joinVideoBoxEmail span {
    border: 3px solid #00a19a;
    width: 120px;
    height: 120px;
    margin-right: 0px;
    z-index: 2 !important;
    line-height: 120px;
    border-radius: 50%;
    text-align: center;
    color: #00a19a;
    font-size: 3.2rem;
    display: block;
}

.joinVideoBox span {
    border: 3px solid #00a19a;
    width: 120px;
    height: 120px;
    margin-right: 0px;
    z-index: 2 !important;
    line-height: 120px;
    border-radius: 50%;
    text-align: center;
    color: #00a19a;
    font-size: 3.2rem;
    display: block;
}


/**join page end**/


/**signup step start**/


/* ============= signup progress bar start ============= */

.signProgStep {
    margin-top: 2rem;
}

.signupProgressIn h4 {
    color: #0f1f50;
    font-size: 18px;
    font-weight: 600;
}

.logingroup label {
    font-size: 1.2rem;
    color: #0E1F50;
}

.loginPlus {
    height: 31px;
    display: flex;
    align-items: center;
}

.loginPlus button {
    border: none;
    background: transparent;
}

.loginPlus i {
    padding: 4px;
    font-size: .8rem;
    border-radius: 50%;
    color: #dfdede;
    border: 1px solid #707070;
}

.sectorYear .btn-group {
    height: 32px;
}

.sectorYear input[type="text"] {
    text-align: center;
}

.sectorYear .btn-group .btn-primary {
    background-color: #0f1f50;
    border-color: #0f1f50;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 3px !important;
    position: relative;
}

.sectorYear .btn-group .btn-primary::before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 10px;
    background: #FFFFFF;
}

.sectorYear .btn-group .btn-primary:first-child:before {
    display: none;
}

.sectorYear .btn-group .btn-primary:last-child {
    border-right: 0px;
}

.sectorYear .btn-group i {
    border-radius: 2px;
    padding: 2px 3px;
    font-size: .85rem;
}

.sectorYear .btn-group .btn-check:focus+.btn-primary,
.sectorYear .btn-group .btn-primary:focus {
    box-shadow: 0 0 0 0.25rem rgb(15 31 80);
}

.signProgStep ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.signProgStep ul li {
    display: block;
}

.signProgStep ul li a {
    display: block;
    font-size: 14px;
    color: #D8D8DA;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.signProgStep ul li a.active {
    color: #00A099;
}

.signProgStep ul li a.active span {
    border: 2px solid #00A099;
    background: #fff;
}

.signProgStep ul li a.active span.fill {
    background: #00A099;
}

.signProgStep ul li a span {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #fff;
    border: 2px solid #D8D8DA;
    display: inline-block;
    margin-right: .7rem;
    position: relative;
    text-align: center;
}

.signProgStep ul li a span.fill i,
.signProgStep ul li a span.fill svg {
    color: #fff;
}

.signProgStep ul li a span::before {
    width: 2px;
    height: 26px;
    background: #D8D8DA;
    position: absolute;
    content: "";
    top: -4px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.signProgStep ul li a.active span::before {
    width: 2px;
    height: 26px;
    background: #00A099;
    position: absolute;
    content: "";
    top: -4px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.signProgStep ul li a:first-child span::before {
    background: transparent;
}


/* ============= signup progress bar exit ============= */

.border_line {
    border-bottom: 1px dashed #7986AC;
    margin-bottom: 40px;
}

.question_box {
    margin-bottom: 40px;
}

.question_box h5 {
    font-size: 14px;
    margin-bottom: 15px;
    line-height: 21px;
}

.question_box .logingroup {
    margin-bottom: 20px;
}


/**signup step1 start**/

.question_box .logingroup {
    margin-bottom: 5px;
}

.select_wrap {
    margin-bottom: 15px;
}

.question_box .row {
    margin-bottom: 15px;
}

.sectorYear .btn-group {
    width: 100%;
    margin-top: 5px;
}

.widthMobile {
    height: 150px;
}

/**radio btn**/

.loginGenderRadio .form-check {
    font-size: 1.6rem;
    display: flex;
    align-items: center;
}

.loginGenderRadio .form-check-input {
    margin-top: 0;
}

.loginGenderRadio .form-check label {
    color: #707070;
    font-size: 13px;
    padding-left: .5rem;
}

.loginGenderRadio .form-check-input[type=radio] {
    border: 1px solid #707070;
    padding: 12px !important;
    width: 35px;
    height: 35px;
    position: relative;
}

.smallWidth {
    width: 25px !important;
    height: 25px !important;
    margin-left: 0px !important;
}

.loginGenderRadio .form-check-input:active {
    background: transparent;
    border-color: #00968E !important;
    background-color: #00968E !important;
}

.loginGenderRadio .form-check-input:focus {
    box-shadow: none;
}

/* .loginGenderRadio .form-check-input[type=radio]:checked::before {
    width: 35px;
    padding: 12px !important;
    height: 35px;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
} */

.horizontalLine {
    height: 1px;
    width: 100%;
    background-color: #707070;
    margin-left: 25px;
    margin-top: -17px;
}

.horizontalLine1 {
    height: 1px;
    width: 110%;
    background-color: #707070;
    margin-left: 0px;
    margin-top: 2px;
}

.horizontalLine2 {
    height: 1px;
    width: 110%;
    background-color: #707070;
    margin-right: -30px;
    margin-top: 2px;
}

.horizontalLineWidth {
    height: 1px;
    width: 11vw;
    background-color: #707070;
    margin-left: -25px;
    margin-right: -55px;
    margin-top: -17px;
}

.columnDisplay {
    flex-direction: column;
    align-items: center;
    padding-left: 0% !important;
}


/**radio btnend**/

.question_box .title {
    color: #00A099;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}


/**togglebtn**/

.button-cover {
    background-color: transparent;
    border-radius: 4px;
}

.button-cover:before {
    counter-increment: button-counter;
    content: counter(button-counter);
    position: absolute;
    right: 0;
    bottom: 0;
    color: #d7e3e3;
    font-size: 12px;
    line-height: 1;
    padding: 5px;
}

.buttonn {
    position: relative;
    width: 60px;
    height: 30px;
    overflow: hidden;
    border: 1px solid #dfdede;
}

.buttonn.r,
.buttonn.r .layer {
    border-radius: 100px;
}

.buttonn.b2 {
    border-radius: 60px;
}

.checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}

.toggle-button-cover {
    margin-right: 10px !important;
}

.knobs {
    z-index: 2;
}

.layer {
    width: 100%;
    background-color: #ebf7fc;
    transition: 0.3s ease all;
    z-index: 1;
}

#button-13 .knobs:before,
#button-13 .knobs:after,
#button-13 .knobs span {
    position: absolute;
    top: -1px;
    width: 25px;
    height: 30px;
    font-size: 11px;
    text-align: center;
    line-height: 1;
    padding: 9px 4px;
    border-radius: 100%;
    transition: 0.3s ease all;
}

#button-13 .knobs:before,
#button-13 .knobs:after {
    color: #4e4e4e;
    z-index: 1;
}

#button-13 .knobs:before {
    content: 'YES';
    left: 3px;
    top: 0px;
}

#button-13 .knobs:after {
    content: 'NO';
    right: 3px;
    top: 0px;
}

#button-13 .knobs span {
    width: 30px;
    left: 29px;
    background-color: #0E1F50;
    z-index: 2;
}

#button-13 .checkbox:checked+.knobs span {
    left: 0px;
    background-color: #dfdede;
}

#button-13 .checkbox:checked~.layer {
    background-color: #fcebeb;
}

.loginSwitch p {
    margin-bottom: 0;
}


/**toggle btn end**/

.signup_table thead tr th {
    font-size: 12px;
    font-weight: 400;
    color: #B8B8BB;
    vertical-align: top;
}

.table td,
.table th {
    border-top: 0ch;
}

.signup_table .table_title {
    font-size: 16px;
    font-weight: 600;
    color: #0E1F50;
    padding-left: 0%;
}

.signup_table .table_title p {
    font-size: 12px;
    font-weight: 40;
}

.navbar-toggler {
    padding: 0%;
    border: none !important;
}


/**signup step end**/


/**********dashbaord page start*********/


/****** project list***/

.sec_title {
    font-size: 28px;
    margin-bottom: 30px;
    font-weight: 500;
}

.projects_list ul li {
    border: 1.2px solid #C5C5C5;
    border-radius: 6px;
    position: relative;
    padding: 12px 250px 12px 200px;
}

.project_logo {
    max-width: 180px;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    border-right: 1.2px dotted #C5C5C5;
    margin-right: 30px;
    position: absolute;
    left: 0;
    top: 0;
}

.right_icons {
    position: absolute;
    right: 0;
    top: 0;
}

.alert_icon a {
    margin: 0 8px;
}

.arrow_btn {
    background: #B3B3B3;
    padding: 14px;
    display: inherit;
    color: #fff;
    border-radius: 0px 4px 4px 0;
}

.arrow_btn:hover {
    background: #0E1F50;
}


/****** project list***/


/****** progress deadline***/

.progress_deadling h3 {
    margin-top: 46px;
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 600;
}

.progress_deadling table {
    color: #B2B2B2;
}

.progress_percentage {
    font-size: 20px;
}

.progress_percentage.complete {
    color: #7AB336;
}

.alert_red {
    color: #FF1A1A;
}

.progress_deadling table thead tr th {
    color: #0E2050;
    font-weight: 400;
    font-size: 16px;
}

.progress_deadling table tbody tr td {
    height: 48px;
}


/****** progress deadline***/

.working_hrs .by_day {
    margin-bottom: 10px;
}

.working_hrs .report p {
    font-size: 12px;
    color: #B2B2B2;
}

.working_hrs .report h3,
.data_box h3 {
    font-size: 24px;
    font-weight: 300;
    color: #B2B2B2;
    margin-top: 5px;
}

.row.border_row {
    border-top: 1px dotted;
}

.row.border_row.border_row_btm {
    border-bottom: 1px dotted;
}

.working_hrs,
.data_box {
    border-right: 1px dotted;
    padding: 20px 0;
    height: 100%;
}

.row.border_row .col-md-4:last-child .working_hrs,
.row.border_row .col-md-3:last-child .data_box,
.row.border_row .col-md-4:last-child .data_box {
    border: none !important;
}

.intrested_project_sec {
    background: #F2FAFD;
}

.intrested_project_box {
    background: #fff;
    border: 1px solid #707070;
    border-radius: 4px;
    display: inherit;
    overflow: hidden;
}

.intrested_project_box:hover .project_category {
    background-color: #0094DA;
}

.project_category {
    background: #0f1f50;
    color: #fff;
    padding: 10px 10px;
    font-size: 14px;
    font-weight: 400;
}

.project_details {
    padding: 10px;
}

.project_details .discription {
    font-size: 12px;
    margin-bottom: 10px;
    font-weight: 300;
    color: #0e1f50;
}

.project_details .duration {
    color: #0e1f50;
}

.project_details .budget {
    color: #00A098;
}

.project_details img {
    max-height: 30px;
    height: 100%;
    margin: 10px 0 20px 0;
}

.down_arrow_btn {
    margin-top: 30px;
    display: inline-block;
    color: #0f1f50;
}

.down_arrow_btn:hover {
    color: #00a098;
}

.intrested_project_sec .container:first-child {
    border-bottom: 1px dotted #0D1F4E;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.refer_to_client {
    padding: 100px;
    position: relative;
    max-height: 253px;
}

.content_refer h3 {
    margin-bottom: 10px;
    color: #fff;
}

.content_refer {
    color: #fff;
}

.content_refer p {
    max-width: 440px;
    margin: 0 auto;
}

.refer_to_client::after {
    content: "";
    background: url(./assets/img/colleague.png);
    height: 253px;
    top: 0;
    position: absolute;
    width: 50%;
    left: 0;
    z-index: -1;
}

.refer_to_client::before {
    content: "";
    background: url(./assets/img/client.png);
    height: 253px;
    top: 0;
    position: absolute;
    width: 50%;
    right: 0;
    z-index: -1;
}

.mlweb-15 {
    margin-left: -15px !important;
}


/**********dashbaord page end*********/


/**project details start**/


/**accordian start**/

.profile_sort_details .profile .profile_image {
    background: #fff;
    height: 70px;
    width: 70px;
}

.s_detail {
    color: #0e2050;
}

.s_detail .name {
    font-size: 18px;
}

.s_detail p.city {
    font-size: 13px;
    color: #B2B2B2;
    margin: 4px 0 8px;
}

.s_detail a {
    font-size: 12px;
    color: #b2b2b2;
}

.skill_box p {
    font-size: 14px;
    color: #0E2050;
    margin-bottom: 5px;
}

.skill_box h3 {
    font-size: 24px;
    font-weight: 300;
    color: #B2B2B2;
    margin-top: 5px;
    margin-bottom: 0;
}

.skills ul li {
    display: inline-block;
    padding: 0 15px;
}

.accordian_tab_wrap {
    width: 100%;
    align-items: center;
}

.team_accordian .accordion-button::after {
    flex-shrink: 0;
    width: inherit;
    height: inherit;
    display: none;
}

.redobtn {
    display: flex;
    align-items: center;
    position: relative;
    right: -32px;
}

.redobtn a {
    color: #0E1F50;
}

.team_accordian .accordion-button:not(.collapsed) {
    background-color: #f2fcfe;
    box-shadow: none;
}

.team_accordian .accordion-body {
    background: #f2fcfe;
    padding: 0 12px;
}

.working_hrs .report h3.font-small,
.data_box h3.font-small {
    font-size: 11px;
}

.team_accordian .accordion-button {
    background: #F5F5F6;
}

.team_accordian .accordion-button:hover {
    background: #f2fcfe;
}

.team_accordian .accordion-item {
    border: none;
    margin-bottom: 20px;
}

.team_accordian .accordion-button .skills {
    display: none;
}

.team_accordian .accordion-button.collapsed .skills {
    display: block;
}

.no_border_rt {
    border: none;
}

.data_box span {
    margin-top: 10px;
    display: inherit;
    width: 32%;
}

.accordion-item.decline .accordion-button,
.accordion-item.decline .accordion-body {
    background: #FAD8D8;
}

.accordion-item.decline .redobtn {
    right: 0;
}

.slide-arrow {
    font-size: 30px !important;
}

.hideWeb {
    display: none !important;
}

.verticalLineWidth {
    height: 30px;
    width: 1px;
    margin-left: 22px;
    margin-bottom: -5px;
    background-color: #0D1F4E;
}

.widthHiehgt {
    display: block;
    margin-top: -30px !important;
    /* width: 50px !important; */
}

.mtw-2 {
    margin-top: 10px;
}

.contentDivMobile {
    display: flex;
    align-items: flex-end !important;
    /* height: 100% !important; */
}

.text-underline {
    text-decoration: underline;
}

.scrollIcon {
    float: right !important;
    z-index: 5 !important;
    position: relative;
    width: 60px;
    height: 80px;
    /* margin-right: 10px;*/
    margin-top: -20px;
    cursor: pointer;
    z-index: 99;
}

.scrollIcon1 {
    float: right;
    margin-right: 10px;
    margin-top: -50px;
    cursor: pointer;
    z-index: 55;
}

.justify-content-custom {
    justify-content: flex-end !important;
}

.reduceVMargin {
    margin-top: 0% !important;
    margin-bottom: 0%;
}

.scientist {
    display: none !important;
}


/**accordian ebd**/


/**project details end**/

@media screen and (max-width:1200px) {
    .top_banner {
        height: 550px;
        background-size: cover;
        background: url(./assets/img/honebgMobile.png);
        background-repeat: no-repeat;
    }

    .second_section {
        background-size: 50%;
        height: 290px;
    }

    .services_sec .content {
        height: 60vh;
    }

    /*dashbaord*/
    .project_logo {
        max-width: 160px;
    }

    .projects_list ul li {
        padding: 12px 190px 12px 170px;
    }

    .alert_icon a {
        margin: 0 4px;
    }

    .sec_title {
        font-size: 22px;
    }
}

@media screen and (max-width:991px) {

    .working_hrs,
    .data_box {
        border-right: none;
        text-align: center;
    }

    .displayMobileIpadHide {
        display: none;
    }

    .team_accordian .accordion-button.collapsed .skills {
        display: none;
    }

    /* .border-right-none {
    border-right: none !important;
    border-right-width: 0px !important;
  } */
}

@media screen and (max-width: 767px) {
    .top_banner {
        height: 450px;
        text-align: center;
        background-size: cover;
    }

    .loginPara {
        height: auto;
        margin-bottom: 50px;
    }

    .mlweb-15 {
        margin-left: 0px !important;
    }

    .videoTag {
        height: 74%;
    }

    .j-end {
        justify-content: flex-end;
    }

    .scientist {
        margin-top: -10px !important;
        display: inline !important;

    }

    .subscribeArrow1 {
        background-color: #dddddd;
        width: 10% !important;
        height: 168px;
        margin-left: -10px;
        margin-bottom: 0px;
        color: #0D1F4E;
    }

    .joinVideo {
        min-height: 300px;
        height: 100%;
        /* margin-bottom: 5%; */
        margin-top: -120px !important;
    }

    .joinVideoEmail {
        min-height: 300px;
        height: 100%;
        /* margin-bottom: 5%; */
        /* margin-top: -120px !important; */
    }

    .joinVideoDardBlack {
        background: #1F1F1F;
        margin-top: -150px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px !important;
        display: flex;
        justify-content: center !important;
        align-items: center;
    }

    .width80 {
        width: 100% !important;
    }

    .mtm-3 {
        margin-top: 30px !important;
    }

    .companyInput {
        width: 90%;
        margin-left: 4%;
    }

    .companyInput1 {
        width: 78%;
        margin-left: 4%;
    }

    .row-mobile-reverse {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: column-reverse;
    }

    .container {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }

    .heading_font,
    .top_banner h1 {
        font-size: 28px;
        margin-bottom: 0% !important;
    }

    .image_box img {
        width: 100%;
    }

    .mobileWidth90 {
        width: 90%;
    }

    .justify-content-custom {
        justify-content: center;
    }

    .ptm-0 {
        padding-top: 0% !important;
    }

    .sm-4 {
        width: 33.33% !important;
    }

    .sm-6 {
        width: 50% !important;
    }

    .hideWeb {
        display: block !important;
    }

    .mtw-2 {
        margin-top: 0px;
    }

    .banner_btm_link {
        display: none;
    }

    .mobileView {
        font-size: 20px !important;
        width: 30%;
        margin-left: 3%;
        line-height: 25px !important;
    }

    .showMobile {
        width: 50% !important;
    }

    .clearfix {
        display: none;
    }

    .signupProgress {
        display: none;
    }

    .banner_content {
        /* margin: 0 auto; */
        margin-top: 80px;
        text-align: left;
    }

    .become_an_expert_div {
        flex-direction: column;
        height: 100% !important;
    }

    .contentHover {
        height: auto !;
    }

    .div-center {
        justify-content: center;
    }

    .font-60 {
        font-size: 30px !important;
    }

    .col-xs-6 {
        width: 50% !important;
    }

    .col-xs-4 {
        width: 33.3% !important;
    }

    .banner_btm_link_div {
        align-items: center;
        flex-direction: column;
    }

    .contentBorder {
        border-right: none !important;
    }

    .footerInputs {
        margin-bottom: 0px;
        width: 100%;
    }

    .banner_btm_link a div {
        left: 50%;
        margin-left: -24px;
    }

    .banner_btm_link a {
        margin-bottom: 10px;
        display: inherit;
    }

    .second_section {
        background-image: none;
        text-align: center;
        height: inherit;
    }

    .sec-4 .content.content-right {
        text-align: center;
        padding-right: 0;
        margin-bottom: 30px;
    }

    .sec-4 .content.content-left {
        text-align: center;
        padding-left: 0;
        margin-top: 30px;
    }

    .services_sec .content {
        height: inherit;
        width: 100%;
        /* border-bottom: 1px solid #fff; */
        /* border-right: none; */
        padding-top: 30px;
        /* text-align: center; */
    }

    .services_sec {
        padding: 0;
        background-position: center;
    }

    .displayMobileHide {
        display: none !important;
    }

    .contentDivMobile {
        display: flex;
        flex-direction: column;
        justify-content: space-between !important;
        align-items: flex-start !important;
        width: 100%;
    }

    .subscribeMobile {
        font-size: 30px;
        color: #fff;
        width: 80%;
        margin-bottom: 20px;
        text-align: left;
    }

    .subs_newsletter .logingroup input {
        color: #fff;
        background: #0E1F50;
        border: 1px solid #fff;
        /* border-right: 40px solid; */
        border-bottom: 1px solid !important;
    }

    .font-mobile-small {
        font-size: 13px !important;
        line-height: normal;
    }

    .rowMobile {
        flex-direction: row-reverse !important;
        display: flex;
        align-items: center;
    }

    .rowMobile1 {
        flex-direction: row !important;
        display: flex;
        align-items: center;
    }

    .mobile-mr-4 {
        margin-right: 10px;
    }

    .service_sec_heading.heading_font {
        position: inherit;
        /* text-align: center; */
        margin-bottom: 10px;
    }

    .services_sec .content p {
        display: none !important;
    }

    .arrow-center-sm {
        justify-content: center;
        flex-direction: column;
        display: flex !important;
    }

    .recent_post_sec .gray_box {
        border: none;
        text-align: center;
        margin: 0 auto;
    }

    .subs_newsletter {
        margin-top: 30px;
        background-color: #0E1F50;
    }

    .blue_bg {
        background-color: #0E1F50;
        padding-bottom: 40px;
    }

    .home_footer {
        padding: 0;
    }

    .company_address {
        text-align: center;
    }

    .common_footer {
        margin-top: -2px;
        margin-right: -2px;
        width: 100%;
        text-align: center;
    }

    .phm-0 {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .fullWidthMobile {
        width: 100% !important;
    }

    .phm-1 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .mhm-0 {
        margin-right: 0% !important;
        margin-left: 0% !important;
    }

    .footleft ul {
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        padding-left: 0;
    }

    .question_box .loginPlus {
        justify-content: center;
    }

    .intrested_project_box {
        margin-bottom: 20px;
    }

    /**signup step1 end**/
    .refer_to_client {
        padding: 35px 0px;
        max-height: inherit;
    }

    .refer_to_client::before {
        height: 100%;
        width: 100%;
    }

    .refer_to_client::after {
        display: none;
    }

    .content_refer {
        color: #fff;
        padding: 15px 0;
    }

    .logingroupUpload {
        flex-direction: column;
        align-items: flex-start;
    }

    .logingroupUpload span {
        margin-top: 10px;
    }

    .mtMobile {
        margin-top: 15px;
    }

    .inputMargin {
        margin-top: 20px;
    }

    .inputMargin1 {
        margin-top: 20px;
    }

    .inputMargin2 {
        margin-top: 20px;
    }

    .marginInputMobile {
        margin-top: -15px;
    }

    .loginGenderRadio {
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .mobilemb {
        margin-bottom: 20px;
    }

    .loginGenderRadio .form-check-input[type=radio] {
        padding: 6px !important;
        accent-color: #00A099;
        /* width: 30px;
        height: 30px; */
        position: relative;
    }

    .loginGenderRadio .form-check label {
        color: #dfdede;
        font-size: 13px;
        margin-top: 5px;
        padding-left: .5rem;
    }

    .horizontalLine {
        height: 1px;
        width: 100%;
        background-color: #707070;
        margin-left: 10px;
        margin-top: -15px;
    }

    .table td,
    .table th {
        padding-left: 0px !important;
    }

    .services_sec .contentDiv {
        /* padding-right: 15px !important; */
        height: 100px !important;
        width: 100%;

    }

    .web80Mobile100 {
        width: 100%;
    }

    .font-40 {
        font-size: 20px !important;
    }

    .font-mobile-34 {
        font-size: 34px !important;
        line-height: 40px;
    }

    .font-mobile-24 {
        font-size: 24px !important;
        line-height: 30px;
    }

    .font-mobile-18 {
        font-size: 18px !important;
        line-height: 30px;
    }

    .font-mobile-16 {
        font-size: 16px !important;
        line-height: 30px;
    }

    .mlm-5 {
        margin-left: 70px;
    }

    .lineHeightMobile {
        line-height: 35px;
    }

    .mtm-5 {
        margin-top: 30px !important;
    }

    .mtm-6 {
        margin-top: 60px !important;
    }

    .widthMobile {
        width: 70%;
    }

    .widthMobile1 {
        width: 50% !important;
    }

    .justify-content-sm-around {
        justify-content: space-around !important;
    }

    .contentDiv {
        /* padding-right: 15px !important; */
        height: 100px !important;
        width: 100%;
        border-bottom: 1px solid #fff;
    }

    .border-right-none {
        border-right: none !important;
        border-right-width: 0px !important;
    }

    .sec4 {
        flex-direction: column;
        align-items: flex-start;
    }

    .firstSection4Sec {
        width: 100%;
        padding-left: 2%;
    }

    .lastSection4Sec {
        width: 100% !important;
    }

    .lastSection4SecContent {
        width: 100% !important;
    }

    .mobileRowBetween {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }

    .subscribeArrow {
        margin-right: 11%;
        color: #0f1f50;
    }

    .font-36 {
        font-size: 24px !important;
    }

    .widthfixed80 {
        width: 60%;
    }

    .font-80-home {
        font-size: 30px !important;
        line-height: 45px !important;
        min-width: 200px !important;
        max-width: 300px !important;
    }

    .font-80-login {
        font-size: 30px !important;
        line-height: 45px !important;
        /* min-width: 200px !important; */
        /* max-width: 400px !important; */
    }

    .homeSubText {
        min-width: 200px !important;
        max-width: 300px !important;
        font-size: 16px !important;
        line-height: 25px !important;
    }


}

@media screen and (max-width: 500px) {
    .projects_list ul li {
        padding: 10px;
        display: inherit !important;
        text-align: center;
    }

    .project_logo {
        position: inherit;
        max-width: 100%;
        margin-bottom: 10px;
    }

    .right_icons {
        position: inherit;
        display: inherit !important;
        margin-top: 21px;
    }

    .arrow_btn {
        border-radius: 5px;
        margin-top: 10px;
        margin-left: 0 !important;
    }
}