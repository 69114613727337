.shipping {
    background-image: url(../../assets/img/shippingbg.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 70vh !important;
    margin-top: 40px;
}

.shippingsubText {
    width: 50%;
}

.shipping_second_section {
    background-image: url(../../assets/img/unsplash.png);
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 400px;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100% !important;
}


.z_index_3 {
    z-index: 3;
}

.trending {
    background-image: url(../../assets/img/Group\ 6584.png);
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 300px;
    width: 100% !important;
}

.social-share {
    margin-top: 150px;
    align-items: center;
    display: flex;
    flex-direction: column;
}



.shareRotate {
    transform: rotate(-90deg);
    margin-top: 20px;
    color: #D8D8DA;
}

.vline {
    height: 100px;
    width: 1px;
    background-color: #D8D8DA;
}

.hline {
    height: 1px;
    width: 100px;
    background-color: #D8D8DA;
}

.round-social {
    border: 1px solid #D8D8DA;
    border-radius: 50px;
    padding-left: 5px;
    width: 50px;
    height: 50px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.margint-2{
    margin-top: 0.5rem;
}

@media screen and (max-width:1200px) {
    .shipping {
        height: 400px !important;
        background-size: cover;
        background-image: url(../../assets/img/shippingBgMobile.png);
    }

    .social-share-mobile {
        margin-top: 10px;
        margin-bottom: 30px;
        display: flex !important;
        flex-direction: row !important;
        align-items: center;
        justify-content: space-between;
    }

    .round-social {
        border: 1px solid #D8D8DA;
        border-radius: 50px;
        width: 40px;
        height: 40px;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0px;
    }


    .shippingsubText {
        width: 90%;
    }


    .shipping_second_section {
        min-height: 170px;
        background-image: url(../../assets/img/shippingMobile.png);
        background-size: contain;
        width: 100vh;
    }

    .margint-2{
        margin-top: 1.7rem;
    }

}