/* explore css */

.explore {
    background-image: url(../../assets/img/explore.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 70vh !important;
    margin-top: 40px;
}

.subText {
    width: 70%;
    margin-bottom: 50px;
}

.borderTop {
    border-top: 1px dashed #101D46;
    padding-top: 10px;
    margin: 0px;
}

.agile-section {
    background-color: rgba(0, 171, 231, 0.05);
}

.shadowBox{
    box-shadow: 0px 1px 10px #d8d8d8;
    margin-bottom: 20px;
    border-radius: 5px;
    padding: 15px;
}

@media screen and (max-width:1200px) {
    .explore {
        height: 400px !important;
        background-image: url(../../assets/img/honebgMobile.png);
        background-size: cover;
    }

    .subText {
        width: 100% !important;
    }
}