.projectList {
    height: 40px;
}

.projectImage {
    padding: 5px 0px 5px 0px;
}

.radius-5 {
    border-radius: 5px;
}

.projectSection {
    margin-top: -50px;
}

.grey-box {
    background-color: #B3B3B3;
    height: 40px;
    width: 50px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #fff;
    cursor: pointer;
}

.grey-box:hover {
    background-color: #0e2050;
}

.sampleClient {
    background-color: #F2FAFD;
}

.sampleClient1 {
    background-color: #D8D8DA;
}

.teamProject {}

.blue-head {
    height: 30px;
    width: 100%;
    background-color: #0e2050;
    padding-top: 5px;
    padding-left: 10px;
}

.black-head {
    height: 30px;
    width: 100%;
    background-color: #363636;
    padding-top: 5px;
    padding-left: 10px;
}

.blue-light-head {
    height: 30px;
    width: 100%;
    background-color: #0094DA;
    padding-top: 5px;
    padding-left: 10px;
}

.white-body {
    background-color: #fff;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;

}

.minus-margin {
    margin-top: -15px;
}

.grayscale {
    filter: grayscale(100%);
}

.recommand {
    background-image: url(../../assets/img/referCollegue.png);
    height: 292px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
}

.refer {
    background-repeat: no-repeat;
    background-image: url(../../assets/img/startProject.png);
    height: 292px;
    width: 100%;
    background-size: contain;
    cursor: pointer;
}

.startProject {
    background-repeat: no-repeat;
    background-image: url(../../assets/img/startProject.png);
    height: 292px;
    width: 100%;
    background-size: contain;
    cursor: pointer;
}

.blue_outline_btn {
    border: 1px solid #18A098;
    color: #18A098;
    border-radius: 100px;
    background-color: #fff;
    width: 120px;
}

.inviteBtn {
    width: 70% !important;
    background-color: transparent;
    height: 50px
}