.bg-grey {
    background-color: rgba(216, 216, 218, 0.25);
}

.antTabsRoot:focus {
    outline: none !important;
    background-color: #fff;
    color: #0E1F50 !important;
}

.antTabsRoot:active {
    outline: none !important;
    background-color: #fff;
    color: #0E1F50 !important;
}

.selected {
    background-color: #fff !important;
    font-weight: bolder !important;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    color: #0E1F50 !important;
}

.unselected {
    color: #969696 !important;
}

.searchInput {
    padding: 10px;
    height: 33px;
    border: 1px solid #dfdede;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bordered {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #D3D3D5;
}

.bordered:hover {
    background-color: #00A099;
    border: 1px solid #00A099;
    color: #fff;
}

.borderedSelected {
    background-color: #00A099;
    border: 1px solid #00A099;
    color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #D3D3D5;
}

.searchInput input {
    font-size: 14px;
    min-width: 200px;
    height: 30px;
    border: none;
}

.searchInput input:focus {
    outline: none;
}

.font-small {
    font-size: 12px;
    color: #000;
    opacity: 0.4;
}

.teamBox {
    background-color: #ebebec;
    width: 100%;
    margin-top: 20px;
    border-radius: 4px;
    height: 120px;
    border: 1px solid #C5C5C5;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    justify-content: space-between;
}

.teamBox:hover {
    background-color: rgba(0, 171, 231, 0.1);
}

.mapSec {
    width: 100%;
    height: 800px;
}

.overviewDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.typeDiv {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center
}

.typeDiv1 {
    min-height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start
}

.reduceMargin {
    margin-top: -5px;
    margin-bottom: -5px;
}

.teamOptionToggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.loadingTeam {
    width: 100%;
    height: 50vh;
}

.react-pdf__Page {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.position-right {
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: flex-end;
}

.logoImage {
    width: 80px;
    height: 80px;
}

.padding-bottom {
    padding-bottom: 0px;
}

.width50 {
    width: 50%;
}

.width30 {
    width: 30% !important;
}

.height800 {
    height: 800px
}

.btn_red {
    border-color: #DB0A1B !important;
    color: #DB0A1B !important;
}

.btn-outline-grey-warning {
    border-color: #8B8B8B !important;
    color: #8B8B8B !important;
    background-color: #fff;
    padding: .75rem 0rem;
    border-radius: 5rem;
    cursor: pointer;
    transition: all .4s ease;
    max-width: 340px;
    width: 100%;
}

.badge {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: #00A099;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -20px;
    margin-left: -0px !important;
    color: #fff
}

.activebadge {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: #F9BA00;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -20px;
    margin-left: -0px !important;
    color: #fff
}

.btn-outline-grey-warning:hover {
    background-color: #8B8B8B !important;
    color: #fff !important;
}

@media screen and (max-width:1200px) {}

@media screen and (max-width:767px) {
    .width50 {
        width: 100%;
    }
}