.insights {
    background-image: url(../../assets/img/Group\ 7125.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 70vh !important;
    margin-top: 40px;

}

.bg-yellow {
    background-color: #F9BA00;
    padding: 40px 50px;
    min-height: 300px;
    display: flex;
    justify-content: flex-end;
}

.stay_up {
    color: #fff;
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.subs_newsletter_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.subs_newsletter_main>div {
    width: 50%;
}

.insight_footer {
    background: #DDDDDD;
}

@media screen and (max-width:1200px) {
    .insights {
        height: 300px !important;
        background-image: url(../../assets/img/insightBgMobile.png);
        background-size: cover;
    }

    .bg-yellow {
        padding: 40px 50px;
    }

    .subs_newsletter_main>div {
        width: 80%;
        margin-bottom: 50px;
    }
}