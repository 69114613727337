/* explore css */

.whoweare {
    background-image: url(../../assets/img/AboutUs.png) !important;
    background-size: cover;
    background-repeat: no-repeat;
    height: 70vh !important;
    margin-top: 40px;
}

.subText {
    width: 70%;
    margin-bottom: 50px;
}

.borderTop {
    border-top: 1px dashed #101D46;
    padding-top: 10px;
    margin: 0px;
}

.agile-section {
    background-color: rgba(0, 171, 231, 0.05);
}

.btn_blue_outline {
    width: 100%;
    background-color: #fff;
    color: #00ABE7;
    border: 1px solid #00ABE7;
}

.modelVLine {
    height: 30px;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.1);
}

.modelHLine {
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
}

.modelCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.width90 {
    width: 90%;
}

.boxGrey {
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 12%;
    height: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #D8D8DA;
    margin-top: 0px !important;
}

.boxGrey:hover {
    border: 1px solid #00ABE7;
    color: #00ABE7;
}

.rowbetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.nexusIcon {
    width: 80%;
    margin-left: 12%
}

@media screen and (max-width:787px) {
    .whoweare {
        height: 400px !important;
        background-image: url(../../assets/img/insightBgMobile.png) !important;
        background-size: cover;
    }

    .boxGrey {
        width: 50% !important;
        flex-direction: column !important;
        margin-top: 30px !important;
    }

    .nexusIcon {
        width: 100% !important;
        margin-left: 0%
    }

    .modelVLineMobile {
        height: 82%;
        /* width: 100%; */
        /* background-color: rgba(0, 0, 0, 0.1); */
        position: absolute;
        left: 46%;
    }

    .rowAbsolute {
        position: absolute;
        left: 50% !important;
        height: 82% !important;
    }

    .rowbetweenColumn {
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
    }

    .modelHLineMobile {
        width: 100%;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.1);
    }
}