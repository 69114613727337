.loginbg {
    background-image: url(../../assets/img/login-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.5) !important;
    height: 100% !important;
    /* margin-top: 50px; */
}

.mobileMT-0 {
    margin-top: 50px;
}

@media screen and (max-width:1200px) {
    .shipping {
        height: 600px !important;
        background-size: cover;
    }

    .loginbg {
        /* background-image: url(../../assets/img/loginBgMobile.png); */
        background-size: cover;
        background-repeat: no-repeat;
        height: 110vh !important;
        /* height: 600px !important; */
    }

    .banner_content>h1 {
        font-size: 24px;
        margin-top: 10px;
        margin-bottom: 0px;
    }

    .banner_content>p {
        font-size: 12px;
    }

    .mobileMT-0 {
        margin-top: 0px;
    }
}